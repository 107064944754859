import { Route, Routes } from 'react-router-dom'
// import Home from './view/home'
// import Login from './view/login'
// import Register from './view/register'
// import E404 from './view/404'
// import ConfirmAccount from './view/confirm-account'
// import Shop from './view/shop'
import { AppContextProvider } from './view/context/context'
// import Checkout from './view/checkout'
// import CustomerDashboard from './view/dashboard'
// import Profile from './view/profile'
// import Orders from './view/orders'
// import Product from './view/product'
// import Order from './view/order'
// import Blog from './view/blog'
// import Post from './view/post'
// import Wishlist from './view/wishlist'
// import Compare from './view/compare'
// import Branches from './view/branches'
// import AboutUs from './view/about-us'
// import ContactUs from './view/contact-us'
// import ProductCategories from './view/product-category'
// import ResetPassword from './view/reset'
// import ChangePassword from './view/changepassword'
// import BankPayment from './view/bankpayment'
// import BankConfirm from './view/bankconfirm'
// import ProductMeta from './view/product-meta'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CacheProvider } from '@emotion/react'
import { prefixer } from 'stylis'
import rtlPlugin from 'stylis-plugin-rtl'
import createCache from '@emotion/cache'
// import Info from './view/info'
// import ProductBrands from './view/product-brand'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
// import PostCategory from './view/post-category'
// import Granting from './view/granting'
import ComingSoon from './view/coming-soon'

export default function Store() {
  const { i18n } = useTranslation()
  document.body.style.direction = i18n.dir()
  document.body.classList.remove('rtl')
  document.body.classList.remove('ltr')
  document.body.classList.add(i18n.dir())
  document.documentElement.style.setProperty('--theme-font-family',i18next.language === 'fa'?'"Shabnam", sans-serif':'helvetica');
  const theme = createTheme({
    direction: i18next.language === 'fa' ? 'rtl' : 'ltr',
    typography: {
      fontFamily: i18next.language === 'fa' ? ['"Shabnam"', 'sans-serif'].join(',') : ['sans-serif'].join(','),
    },
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    palette: {
      primary: {
        light: '#e9ecf4',
        main: '#234090',
        dark: '#2c3333',
        contrastText: '#fff',
      },
      secondary: {
        light: '#e9ecf4',
        main: '#ec4899',
        dark: '#2c3333',
        contrastText: '#fff',
      },
      light: {
        light: '#ccc',
        main: '#666666',
        dark: '#2c3333',
        contrastText: '#fff',
      },
    },
  })
  // Create rtl cache
  const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
  })

  const cacheLtr = createCache({
    key: 'muiltr',
    stylisPlugins: [prefixer],
  })

  return (
    <div>
      <Helmet
        htmlAttributes={{
          lang: i18next.language,
          dir: i18next.language === 'fa' ? 'rtl' : 'ltr',
        }}
      />

      <CacheProvider value={i18next.language === 'fa' ? cacheRtl : cacheLtr}>
        <ThemeProvider theme={theme}>
          <AppContextProvider>
            <Routes>
              <Route path="/" element={<ComingSoon />} />
              {/* <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/reset" element={<ResetPassword />} />
              <Route path="/register" element={<Register />} />
              <Route path="/confirm/:mobile" element={<ConfirmAccount />} />
              <Route path="/shop" element={<Shop />} />
              <Route path="/product/:slug" element={<Product />} />
              <Route
                path="/product-category/:slug"
                element={<ProductCategories />}
              />
              <Route path="/product-meta/:id" element={<ProductMeta />} />
              <Route path="/product-brand/:id" element={<ProductBrands />} />
              <Route path="/checkout" element={<Checkout />} />
              <Route path="/dashboard" element={<CustomerDashboard />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/order/:id" element={<Order />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/changepassword" element={<ChangePassword />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/post/:slug" element={<Post />} />
              <Route path="/post-category/:slug" element={<PostCategory />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/compare" element={<Compare />} />
              <Route path="/branches" element={<Branches />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/granting" element={<Granting />} />
              <Route path="/info" element={<Info />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/bank/:id" element={<BankPayment />} />
              <Route path="/payment-confirm" element={<BankConfirm />} />
              <Route path="/panel/*" />
              <Route path="/*" element={<E404 />} /> */}
            </Routes>
          </AppContextProvider>
        </ThemeProvider>
      </CacheProvider>
    </div>
  )
}
