import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import Store from './store'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import './assets/js/custom'
import './i18nextConf'
import i18next from 'i18next'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={1500}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: i18next.language === 'fa' ? 'right' : 'left',
      }}
    >
      <Store />
    </SnackbarProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
