import React, { useState } from 'react'
import jwt_decode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

const AppContext = React.createContext({
  customer: {},
  setCustomer: () => {},
  getCustomer: () => {},
  cart: {},
  setCart: () => {},
  login: () => {},
  isLoggedIn: () => {},
  logout: () => {},
  getToken: () => {},
  wishlist: {},
  setWishlist: () => {},
  compareList: {},
  setCompareList: () => {},
  searchTerm: {},
  setSearchTerm: () => {},
  addToCart: () => {},
  currentLanguage: {},
  setCurrentLanguage: () => {},
  NormalizeMobile: () =>{}
})

export const AppContextProvider = (props) => {
  // const PUBLIC_URL = 'http://localhost:8081/zivan/store'
  const PUBLIC_URL = ''
  const WEBSITE_URL = 'https://zivanpet.com'
  const DEFAULT_LANGUAGE = 'fa'
  const DEFAULT_COUNTRY = 'iran'
  const { t } = useTranslation()
  const CURRENCY = [
    {
      id: 1,
      text: t('toman'),
      place: 'after',
    },
    {
      id: 2,
      text: t('dolar'),
      place: 'before',
    },
  ]
  const [currentCurrency, setCurrentCurrency] = useState(CURRENCY[0])
  const [currentLanguage, setCurrentLanguage] = useState(DEFAULT_LANGUAGE)
  const navigate = useNavigate()
  const [cart, setCart] = useState([])
  const [wishlist, setWishlist] = useState([])
  const [compareList, setCompareList] = useState([])
  const [loggedIn, setIsLoggedIn] = useState(false)
  const [customer, setCustomer] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const { enqueueSnackbar } = useSnackbar()

  const autoLogin = useCallback(async () => {
    if (getToken()) {
      try {
        const token = getToken()
        if (token !== '') {
          await fetch(`${PUBLIC_URL}/api/customer/login`, {
            headers: {
              Authorization: 'Bearer ' + token,
            },
          })
            .then((respose) => {
              if (respose.ok) {
                return respose.json()
              }
              throw new Error('error')
            })
            .then((data) => {
              if (data.token) {
                localStorage.setItem('token', data.token)
                setIsLoggedIn(true)
              }
            })
        }
      } catch (error) {}
    }
  }, [])

  useEffect(() => {
    if (!isLoggedIn()) {
      autoLogin()
    }
  }, [autoLogin])

  const login = async (username, password, loader, setError) => {
    try {
      await fetch(`${PUBLIC_URL}/api/customer/login`, {
        method: 'POST',
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      })
        .then((respose) => {
          if (respose.ok) {
            return respose.json()
          }
          throw new Error('error')
        })
        .then(async (data) => {
          if (data.token) {
            localStorage.setItem('token', data.token)
            setIsLoggedIn(true)
            loader(1)
            let localcart = localStorage.getItem('cart')
            localcart = localcart ? JSON.parse(localcart) : []
            let localwish = localStorage.getItem('wish')
            localwish = localwish ? JSON.parse(localwish) : []
            let localcompare = localStorage.getItem('compare')
            localcompare = localcompare ? JSON.parse(localcompare) : []

            const lists = {
              cart: [...localcart],
              wish: [...localwish],
              compare: [...localcompare],
            }

            if (
              localcart.length > 0 ||
              localwish.length > 0 ||
              localcompare.length > 0
            ) {
              await fetch(`${PUBLIC_URL}/api/customer/updatecart`, {
                method: 'POST',
                body: JSON.stringify({
                  lists: lists,
                }),
                headers: {
                  Authorization: 'Bearer ' + data.token,
                },
              })
                .then((respose) => {
                  if (respose.ok) {
                    return respose.json()
                  }
                  throw new Error('error')
                })
                .then((data) => {
                  if (data.status === 1) {
                    localStorage.removeItem('cart')
                    localStorage.removeItem('wish')
                    localStorage.removeItem('compare')
                  }
                  navigate('/')
                })
            } else {
              navigate('/')
            }
          } else if (data.mobile) {
            navigate(`/confirm/${data.mobile}`)
          } else {
            setError(t('error_username_or_password'))
            loader(0)
          }
        })
    } catch (error) {
      setError(t('khata_dar_amaliyat'))
      loader(0)
    }
  }

  const logout = () => {
    localStorage.removeItem('token')
    setIsLoggedIn(false)
    navigate('/login')
  }

  const isLoggedIn = () => {
    return loggedIn
  }

  const getToken = () => {
    const token = localStorage.getItem('token')
    return token ? token : ''
  }

  const getCustomer = () => {
    const customer = localStorage.getItem('token')
    if (customer) {
      return jwt_decode(customer)
    }
    return 0
  }

  const addToCart = async (attribute_id, count, setError, setLoader) => {
    try {
      if (getToken()) {
        await fetch(`${PUBLIC_URL}/api/cart/addtocart`, {
          method: 'POST',
          body: JSON.stringify({
            count: count,
            aid: attribute_id,
          }),
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            if (data.status === 0) {
              setError && setError(t('khata_dar_amaliyat'))
              setLoader && setLoader(0)
            } else {
              setCart(data)
              setLoader && setLoader(2)
              enqueueSnackbar(t('mahsul_be_sabade_kharid_ezafe_shod'), {
                variant: 'success',
                vertical: 'bottom',
                horizontal: 'left',
              })
            }
          })
      } else {
        let localCart = localStorage.getItem('cart')

        if (localCart) {
          localCart = JSON.parse(localCart)

          const foundItem = localCart.find((item) => {
            return item.aid === attribute_id
          })
          if (foundItem) {
            const newlocalCart = localCart.map((item) => {
              if (attribute_id === item.aid) {
                return { ...item, count: count }
              }
              return item
            })
            localStorage.setItem('cart', JSON.stringify(newlocalCart))
          } else {
            localStorage.setItem(
              'cart',
              JSON.stringify([
                ...localCart,
                { count: count, aid: attribute_id },
              ]),
            )
          }
        } else {
          localStorage.setItem(
            'cart',
            JSON.stringify([{ count: count, aid: attribute_id }]),
          )
        }
        await fetch(`${PUBLIC_URL}/api/cart/cartlist`, {
          method: 'POST',
          body: JSON.stringify({
            localcart: localStorage.getItem('cart'),
          }),
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setCart(data)
            setLoader && setLoader(2)
            enqueueSnackbar(t('mahsul_be_sabade_kharid_ezafe_shod'), {
              variant: 'success',
              vertical: 'bottom',
              horizontal: 'left',
            })
          })
      }
    } catch (error) {
      setError && setError(t('khata_dar_amaliyat'))
      setLoader && setLoader(0)
    }
  }

  const clearCart = () => {
    localStorage.removeItem('cart')
    setCart([])
  }

  const addToWishlist = async (event, id) => {
    try {
      if (getToken()) {
        await fetch(`${PUBLIC_URL}/api/product/addtowishlist`, {
          method: 'POST',
          body: JSON.stringify({
            pid: id,
          }),
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            if (data.status === 0) {
              //set error
            } else {
              setWishlist(data)
              enqueueSnackbar(t('afzudane_mahsul_be_alaghemandiha'), {
                variant: 'success',
                vertical: 'bottom',
                horizontal: 'left',
              })
            }
          })
      } else {
        let localWishlist = localStorage.getItem('wish')

        if (localWishlist) {
          localWishlist = JSON.parse(localWishlist)

          const foundItem = localWishlist.find((item) => {
            return item.id === id
          })
          if (foundItem) {
            const newlocalWishlist = localWishlist.filter((item) => {
              if (id !== item.id) {
                return item
              }
            })
            localStorage.setItem('wish', JSON.stringify(newlocalWishlist))
          } else {
            localStorage.setItem(
              'wish',
              JSON.stringify([...localWishlist, { id: id }]),
            )
            enqueueSnackbar(t('afzudane_mahsul_be_alaghemandiha'), {
              variant: 'success',
              vertical: 'bottom',
              horizontal: 'left',
            })
          }
        } else {
          localStorage.setItem('wish', JSON.stringify([{ id: id }]))
        }
        await fetch(`${PUBLIC_URL}/api/product/wishlist`, {
          method: 'POST',
          body: JSON.stringify({
            localwish: localStorage.getItem('wish'),
          }),
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setWishlist(data)
          })
      }
    } catch (error) {}
  }

  const addToCompare = async (event, id) => {
    try {
      if (getToken()) {
        await fetch(`${PUBLIC_URL}/api/product/addtocompare`, {
          method: 'POST',
          body: JSON.stringify({
            aid: id,
          }),
          headers: {
            Authorization: 'Bearer ' + getToken(),
          },
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            if (data.status === 0) {
              //set error
            } else {
              setCompareList(data)
              enqueueSnackbar(t('afzudane_mahsul_be_moghayese'), {
                variant: 'success',
                vertical: 'bottom',
                horizontal: 'left',
              })
            }
          })
      } else {
        let localCompare = localStorage.getItem('compare')

        if (localCompare) {
          localCompare = JSON.parse(localCompare)

          const foundItem = localCompare.find((item) => {
            return item.id === id
          })
          if (foundItem) {
            const newlocalCompare = localCompare.filter((item) => {
              if (id !== item.id) {
                return item
              }
            })
            localStorage.setItem('compare', JSON.stringify(newlocalCompare))
          } else {
            localStorage.setItem(
              'compare',
              JSON.stringify([...localCompare, { id: id }]),
            )
            enqueueSnackbar(t('afzudane_mahsul_be_moghayese'), {
              variant: 'success',
              vertical: 'bottom',
              horizontal: 'left',
            })
          }
        } else {
          localStorage.setItem('compare', JSON.stringify([{ id: id }]))
        }
        await fetch(`${PUBLIC_URL}/api/product/compare`, {
          method: 'POST',
          body: JSON.stringify({
            localcompare: localStorage.getItem('compare'),
          }),
        })
          .then((respose) => {
            if (respose.ok) {
              return respose.json()
            }
            throw new Error('error')
          })
          .then((data) => {
            setCompareList(data)
          })
      }
    } catch (error) {}
  }

  const NormalizeMobile = (mobile)=>{
    switch(currentLanguage){
      case 'fa':
        return mobile.replace('0098', '0')
        break
    }
  }

  return (
    <AppContext.Provider
      value={{
        customer,
        setCustomer,
        getCustomer,
        cart,
        setCart,
        login,
        isLoggedIn,
        setIsLoggedIn,
        logout,
        getToken,
        wishlist,
        setWishlist,
        compareList,
        setCompareList,
        searchTerm,
        setSearchTerm,
        addToCart,
        clearCart,
        addToWishlist,
        addToCompare,
        PUBLIC_URL,
        WEBSITE_URL,
        DEFAULT_LANGUAGE,
        DEFAULT_COUNTRY,
        CURRENCY,
        currentCurrency,
        setCurrentCurrency,
        currentLanguage,
        setCurrentLanguage,
        NormalizeMobile
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContext
