if (window.innerWidth > 767) {
  window.addEventListener('scroll', function (ev) {
    var sticky_header = document.querySelector('.menu-header')
    if (sticky_header) {
      if (window.scrollY >= 400) {
        sticky_header.classList.add('menu-header-sticky')
      } else {
        sticky_header.classList.remove('menu-header-sticky')
      }
    }
  })
}
