import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import BG from "../assets/img/coming-soon-bg.jpg";
import LOGO from "../assets/img/logo.svg";

export default function ComingSoon() {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("website_name")}</title>
        <meta name="description" content={t("zivan_meta_description")} />
      </Helmet>
      <main className="Home relative overflow-x-hidden w-full h-full text-[#828282]">
        <header
          id="fh5co-header"
          className={`fh5co-cover h-[100vh] relative w-full`}
          role="banner"
          style={{
            backgroundImage: `url(${BG})`,
            backgroundPosition: "0% 0%",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          data-stellar-background-ratio="0.5"
        >
          <div className="overlay absolute z-0 bg-[rgba(0,0,0,0.5)] bottom-0 top-0 right-0 left-0"></div>
          <div className="container absolute top-0 left-0 z-[1]">
            <nav
              className="fh5co-nav m-0 p-[40px_0] z-[1001] w-full"
              role="navigation"
            >
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 text-center">
                    <div id="fh5co-logo" className="m-[20px_0_0_0] p-0 flex items-center justify-center">
                        <img src={LOGO} className="w-[200px]" />
                    </div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center">
                <div className="display-t table pt-[20px] w-full">
                  <div
                    className="display-tc animate-box fadeIn animated-fast"
                    data-animate-effect="fadeIn"
                  >
                    <h1 className="mt-[20px] text-[60px] text-white">
                      آماده‌ی یک تجربه‌ی جدید باشید!
                    </h1>
                    <h2 className="text-[20px] mb-[40px] text-white">
                      وب‌سایت جدید ما در راه است. با ما همراه باشید .
                    </h2>
                    <h2 className="text-[20px] mb-[10px] text-white">
                      شروع کار وبسایت
                    </h2>
                    <div className="simply-countdown simply-countdown-one my-[2em] mt-0">
                      <div className="simply-section simply-days-section inline-block w-[110px] h-[120px] bg-[rgba(0,0,0,0.2)] border-solid border-b-[4px] border-[rgba(250,70,89,0.5)] my-[7px] relative">
                        <div className="table-cell align-middle h-[100px] w-[110px]">
                          <span className="simply-amount text-[40px] text-white block">
                            15
                          </span>
                          {/* <span className="simply-word text-[18px] uppercase text-[rgba(255,255,255,0.7)]">
                            تیر
                          </span> */}
                        </div>
                      </div>
                      <div className="simply-section simply-hours-section inline-block w-[110px] h-[120px] bg-[rgba(0,0,0,0.2)] border-solid border-b-[4px] border-[rgba(250,70,89,0.5)] my-[7px] relative">
                        <div className="table-cell align-middle h-[100px] w-[110px]">
                          <span className="simply-amount text-[40px] text-white block">
                            تیر
                          </span>
                          {/* <span className="simply-word text-[18px] uppercase text-[rgba(255,255,255,0.7)]">
                            ساعت
                          </span> */}
                        </div>
                      </div>
                      <div className="simply-section simply-minutes-section inline-block w-[110px] h-[120px] bg-[rgba(0,0,0,0.2)] border-solid border-b-[4px] border-[rgba(250,70,89,0.5)] my-[7px] relative">
                        <div className="table-cell align-middle h-[100px] w-[110px]">
                          <span className="simply-amount text-[40px] text-white block">
                            1403
                          </span>
                          {/* <span className="simply-word text-[18px] uppercase text-[rgba(255,255,255,0.7)]">
                            دقیقه
                          </span> */}
                        </div>
                      </div>
                      {/* <div className="simply-section simply-seconds-section inline-block w-[110px] h-[120px] bg-[rgba(0,0,0,0.2)] border-solid border-b-[4px] border-[rgba(250,70,89,0.5)] my-[7px] relative">
                        <div className="table-cell align-middle h-[100px] w-[110px]">
                          <span className="simply-amount text-[40px] text-white block">
                            37
                          </span>
                          <span className="simply-word text-[18px] uppercase text-[rgba(255,255,255,0.7)]">
                            ثانیه
                          </span>
                        </div>
                      </div> */}
                    </div>
                    {/* <div className="row">
                      <ul className="fh5co-social-icons">
                        <li>
                          <a href="#">
                            <i className="icon-twitter-with-circle"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-facebook-with-circle"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-linkedin-with-circle"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="icon-dribbble-with-circle"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </main>
    </>
  );
}
