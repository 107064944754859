import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["fa"];
const availableLanguages = ["en", "fa"];

i18n
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
    resources: {
      en: {
        translation: {
          website_name: "Zivan Pet Shop",
          mahsulate_shegeftangiz: "Amazing Products",
          mahsulate_zivan: "Zivan Products",
          anvae_ghazaha_va_lavazeme_janebi:
            "All kinds of food and accessories for animals",
          ersal_be_tamame_noghate_keshvar:
            "Shipping to all parts of the country",
          search: "Search ...",
          hazf_ba_khata: "Deletion failed.",
          khata_dar_amaliyat: "Error",
          sabade_kharid: "Cart",
          nabude_mahsule_betedade_varedshode:
            "The product is not available in the warehouse according to the quantity entered.",
          total: "Total",
          checkout: "Checkout",
          mahsuli_baraye_nomayesh_vojud_nadarad:
            "There are no products to display.",
          login: "Login",
          wishlist: "Wishlist",
          compare: "Compare",
          welcome: "Welcome!",
          dashboard: "Dashboard",
          profile: "Profile",
          orders: "Orders",
          exit: "Exit",
          categories: "Categories",
          home: "Home",
          store: "Shop",
          articles: "Articles",
          branches: "Zivan Branches",
          about_us: "About Us",
          contact_us: "Contact Us",
          hazfe_mahsul_az_moghayese:
            "The product was removed from the compare list.",
          afzudane_mahsul_be_moghayese:
            "The product was added to the compare list.",
          afzudane_mahsul_be_list_exist_notification:
            "The product has been added to the list. We will notify you via SMS or Email if available.",
          mahsule_mojud_ast_dar_list_exist_notification:
            "The product is already in the list.",
          exist_notification: "Notify me when available!",
          mobile_or_email: "Mobile or Email",
          mobile_or_email_ra_vared_konid: "Enter your mobile number or email.",
          back: "Back",
          forushghah_malzumate_heyvanat: "Pet supplies store",
          products: "Products",
          discount: "Discount",
          tedade_mahsul_ra_vared_konid: "Please enter the number of products.",
          mojud_dar_anbar: "Available in stock",
          add_to_cart: "Add to cart",
          brand: "Brand",
          made: "Made",
          product_information: "Product Information",
          namojud_dar_anbar: "Not in stock",
          takhfif_ta_tarikh: "Discount until date",
          share: "Share",
          afzudane_mahsul_be_alaghemandiha:
            "The product was added to the wishlist.",
          hazfe_mahsul_az_alaghemandiha:
            "The product was removed from the wishlist.",
          error_username_or_password: "Username or password is wrong.",
          mahsul_be_sabade_kharid_ezafe_shod:
            "The product has been added to the cart.",
          account: "Account",
          long_title: "Zivan Pet Shop Animal food and supplies",
          shomare_tamas: "Phone: 09120892308",
          pasokhguyi: "Support hours: 10AM - 20PM",
          main_page: "Main Page",
          copyright: "All rights reserved.",
          error_404: "404 Error - Zivan Pet Shop",
          e404_content: "Page not found",
          back_to_store: "Back To Store",
          about_us_page_title: "About Zivan pet shops",
          vaziyate_tarakonesh: "Transaction status",
          order_number: "Order number",
          order_date: "Order Date",
          reference_number: "Reference Number",
          count: "Count",
          unit_price: "Unit Price",
          tax: "Tax",
          customer_name: "Customer Name",
          company_name: "Company Name",
          address: "Address",
          postal_code: "Postal Code",
          phone: "Phone",
          mobile: "Mobile",
          darhale_enteghal_be_darghah: "Transferring to the payment gateway",
          adame_enteghal_be_darghah:
            "If there is no automatic transfer to the portal, click on the button.",
          enteghal_be_darghah: "Transfer to the bank portal",
          view_article: "View Article",
          ramz_ra_vared_konid: "Please enter the password.",
          amaliyat_namovafagh: "Error in the process",
          taghier_ramz: "Change password",
          currentPassword: "Current Password",
          password: "Password",
          tekrare_ramz: "Confirm Password",
          back_to_profile: "Back To Profile",
          name_khod_ra_vared_konid: "Insert your first name and last name.",
          address_khod_ra_vared_konid: "Insert your address.",
          ostan_ra_vared_konid: "Insert your province.",
          shahr_ra_vared_konid: "Insert your city",
          mobile_or_phone_ra_vared_konid:
            "Enter your phone number or mobile number.",
          code_takhfif_eshtebah:
            "The discount code is wrong or the number of its use is over.",
          khata_dar_sabte_sefaresh: "Error in order registration",
          code_takhfif_eshtebah_ast: "The discount code is not valid.",
          select_address: "Select Address",
          email: "Email",
          name: "Name",
          province_name: "Province Name",
          city_name: "City Name",
          purchase_note: "Purchase Note",
          product_name: "Product Name",
          price: "Price",
          code_takhfif: "Discount code",
          emale_takhfif: "Process Discount",
          saghfe_takhfif: "You can use this discount code up to %amount%.",
          nahve_pardakht: "Purchase Method",
          pardakhht_ba_saman: "Payment through Saman Bank portal",
          nahve_ersal: "Shipping Method",
          nahve_ersal_1:
            "With courier fee and payment by the customer at home - delivery within the next 24 hours",
          nahve_ersal_2:
            "Shipping to the city is free with an amount of 500 thousand tomans and above and a weight of up to 2 kg.",
          nahve_ersal_ra_entekhab_konid: "Please select a shipping method.",
          purchase: "Purchase",
          mahsuli_baraye_pardakht_nist: "There are no products to purchase",
          code_taid_ra_vared_konid: "Enter confirm code.",
          code_taid_eshtebah_ast: "The entered code is incorrect.",
          code_taid_dobare_ersal_shod:
            "The verification code has been sent again.",
          taid_hesab_karbari: "Confirm Account",
          confirm_code: "Confirm Code",
          confirm: "Confirm",
          ersale_dobare_code_taid: "Re-send the verification code",
          amuzeshhaye_zivan: "Zhivan Trainings",
          amuzeshe_anvae_mataleb:
            "Teaching all kinds of material about animals",
          webloge_zivan: "Weblog",
          mahsulate_sag: "Dog products",
          mahsulate_gorbe: "Cat products",
          tazmin_keyfiyate_kala: "Product quality assurance",
          zemanate_bazghasht_vajh: "Money back guarantee",
          pasokhguyi_ali: "Excellent response",
          namekarbar_mobile_email_ra_vared_konid:
            "Enter your username or mobile number or email.",
          username_or_mobile_or_email: "Username or Mobile or Email",
          register: "Register",
          reset_password: "Reset Password",
          order: "Order",
          ta_mablaghe: "Until amount",
          dawnload_factor: "Download Factor",
          vaziyate_sefaresh: "Order Status",
          sefaresh_baraye_nomayesh_nist: "There are no orders to display.",
          az: "from",
          zivan_store: "Zivan Store",
          filters: "Filters",
          barasase_dastebandi: "By categories",
          barasase_brand: "By brand",
          more_products: "More Products",
          nazare_khod_ra_vared_konid: "Please enter your comment.",
          nazare_shoma_ba_movafaghiyat_sabt_shod:
            "Your comment has been successfully registered. It will be displayed to users after the administrator's approval.",
          khata_dar_sabt_nazar: "Error in comment registration",
          mahsulate_mortabet: "Related Products",
          profile_virayesh_shod: "Profile edited successfully.",
          form_ra_por_konid: "Please enter the form completely.",
          moshakhasate_heyvanat_sabt_shod: `Pet profile successfully registered.`,
          nam_eshtebah_ast: "The name is wrong.",
          heyvane_khanegi_vojud_darad:
            "A pet with this profile already exists.",
          khata_dar_sabt: "Error in registration",
          heyvan_hazf_shod: `Pet profile successfully deleted.`,
          khata_dar_hazf: "Error deleting",
          username: "Username",
          edit: "Edit",
          pet_name: "Pet Name",
          age: "Age",
          type: "Type",
          breed: "Breed",
          aya_az_hazfe_heyvan_motmaenid:
            "Are you sure you want to remove the pet?",
          delete: "Delete",
          afzudane_heyvan: "Add a pet",
          username_ra_vared_konid: "Please enter your username.",
          username_eshtebah_ast: "Username is wrong.",
          hesab_az_gabl_hast:
            "An account with these specifications already exists.",
          mobile_eshtebah_ast: "The mobile number is wrong.",
          email_eshtebah_ast: "The email is wrong.",
          username_shamele_horuf_mibashad:
            "Username includes English lowercase and uppercase letters, numbers, (_) and (.).",
          namekarbar_mobile_email_eshtebah_ast:
            "Username or mobile number or email is wrong.",
          barasase_ghimat: "By Price",
          toman: "Tooman",
          dolar: "$",
          pardakht_nashode: "Not purchased",
          sefaresh_jadid: "New order",
          darhale_amadesazi: "In progress",
          amade_ersal: "Ready to send",
          ersal_shode: "Sent",
          tahvil_dadeshode: "Delivered",
          darhale_barasi_pardakht: "In purchase progress",
          register_review: "Register Review",
          zivan_meta_description:
            "Zivan Pet Shop, pet food and accessories store",
          contact_us_title: "Contact Us",
          posti_baraye_nomayesh_vojud_nadarad: "There are no posts to display.",
          etaye_nomayandegi: "Granting Agency",
          code_melli: 'National Code',
          birthdate: 'Birthdate',
          shomare_tamas: 'Contact Number',
          shabakehayeh_ejtemai: 'Social Media'
        },
      },
      fa: {
        translation: {
          website_name: "پت شاپ ژیوان",
          mahsulate_shegeftangiz: "محصولات شگفت انگیز",
          mahsulate_zivan: "محصولات ژیوان",
          anvae_ghazaha_va_lavazeme_janebi:
            "انواع غذاها و لوازم جانبی برای حیوانات",
          ersal_be_tamame_noghate_keshvar: "ارسال به تمام نقاط کشور",
          search: "جستجو ...",
          hazf_ba_khata: "حذف با خطا مواجه شد.",
          khata_dar_amaliyat: "خطا در عملیات",
          sabade_kharid: "سبد خرید",
          nabude_mahsule_betedade_varedshode:
            "به تعداد وارد شده محصول در انبار موجود نمی باشد.",
          total: "مجموع",
          checkout: "تسویه حساب",
          mahsuli_baraye_nomayesh_vojud_nadarad:
            "محصولی برای نمایش وجود ندارد.",
          login: "ورود",
          wishlist: "لیست علاقه مندی ها",
          compare: "مقایسه محصولات",
          welcome: "خوش آمدید!",
          dashboard: "داشبورد",
          profile: "پروفایل",
          orders: "سفارش ها",
          exit: "خروج",
          categories: "دسته بندی ها",
          home: "خانه",
          store: "فروشگاه",
          articles: "آموزشها",
          branches: "شعب ژیوان",
          about_us: "درباره ما",
          contact_us: "ارتباط با ما",
          hazfe_mahsul_az_moghayese: "محصول از لیست مقایسه ها حذف شد.",
          afzudane_mahsul_be_moghayese: "محصول به لیست مقایسه ها اضافه شد.",
          afzudane_mahsul_be_list_exist_notification:
            "محصول به لیست اضافه شد. در صورت موجود شدن از طریق پیامک یا ایمیل اعلام خواهیم کرد.",
          mahsule_mojud_ast_dar_list_exist_notification:
            "محصول از قبل در لیست موجود می باشد.",
          exist_notification: "موجود شد خبرم کن!",
          mobile_or_email: "شماره موبایل یا ایمیل",
          mobile_or_email_ra_vared_konid:
            "شماره موبایل یا ایمیل خود را وارد نمایید.",
          back: "بازگشت",
          forushghah_malzumate_heyvanat: "فروشگاه ملزومات حیوانات خانگی",
          products: "محصولات",
          discount: "تخفیف",
          tedade_mahsul_ra_vared_konid: "لطفا تعداد خرید را وارد نمایید.",
          mojud_dar_anbar: "موجود در انبار",
          add_to_cart: "افزودن به سبد",
          brand: "برند",
          made: "ساخت",
          product_information: "اطلاعات محصول",
          namojud_dar_anbar: "موجود نیست",
          takhfif_ta_tarikh: "تخفیف تا تاریخ",
          share: "اشتراک گذاری",
          afzudane_mahsul_be_alaghemandiha:
            "محصول به لیست علاقه مندی ها اضافه شد.",
          hazfe_mahsul_az_alaghemandiha: "محصول از لیست علاقه مندی ها حذف شد.",
          error_username_or_password: "نام کاربری یا رمز عبور اشتباه می باشد.",
          mahsul_be_sabade_kharid_ezafe_shod: "محصول به سبد خرید اضافه شد.",
          account: "حساب کاربری",
          long_title: "پت شاپ ژیوان | غذا و لوازم حیوانات",
          shomare_tamas: "شماره تماس: ۰۹۱۲۰۸۹۲۳۰۸",
          pasokhguyi: "ساعت پاسخگویی: ۱۰ صبح تا 20 شب",
          main_page: "صفحه اصلی",
          copyright:
            "تمام حقوق این سایت متعلق به پت شاپ ژیوان می باشد و کپی برداری از آن با ذکر منبع بلامانع است.",
          error_404: "خطا - پت شاپ ژیوان",
          e404_content: "صفحه مورد نظر شما یافت نشد",
          back_to_store: "بازگشت به فروشگاه",
          about_us_page_title: "درباره پت‌شاپ‌های زنجیره‌ای ژیوان",
          vaziyate_tarakonesh: "وضعیت تراکنش",
          order_number: "شماره سفارش",
          order_date: "تاریخ سفارش",
          reference_number: "شماره مرجع",
          count: "تعداد",
          unit_price: "قیمت واحد",
          tax: "مالیات",
          customer_name: "نام مشتری",
          company_name: "نام شرکت",
          address: "آدرس",
          postal_code: "کد پستی",
          phone: "تلفن",
          mobile: "موبایل",
          darhale_enteghal_be_darghah: "درحال انتقال به درگاه پرداخت",
          adame_enteghal_be_darghah:
            "در صورت عدم انتقال اتوماتیک به درگاه، بر روی دکمه کلیک نمایید.",
          enteghal_be_darghah: "انتقال به درگاه",
          view_article: "مشاهده مطلب",
          ramz_ra_vared_konid: "لطفا رمز عبور را وارد کنید.",
          amaliyat_namovafagh: "عملیات ناموفق",
          taghier_ramz: "تغییر رمز عبور",
          currentPassword: "رمز عبور فعلی",
          password: "رمز عبور",
          tekrare_ramz: "تکرار رمز عبور",
          back_to_profile: "بازگشت به پروفایل",
          name_khod_ra_vared_konid: "نام و نام خانوادگی خود را وارد کنید.",
          address_khod_ra_vared_konid: "آدرس خود را وارد کنید.",
          ostan_ra_vared_konid: "استان خود را انتخاب کنید.",
          shahr_ra_vared_konid: "شهر خود را انتخاب کنید.",
          mobile_or_phone_ra_vared_konid:
            "شماره تلفن و یا شماره موبایل خود را وارد کنید.",
          code_takhfif_eshtebah:
            "کد تخفیف اشتباه می باشد و یا تعداد استفاده آن تمام شده است.",
          khata_dar_sabte_sefaresh: "خطا در ثبت سفارش",
          code_takhfif_eshtebah_ast: "کد تخفیف صحیح نمی باشد.",
          select_address: "انتخاب آدرس",
          email: "ایمیل",
          name: "نام و نام خانوادگی",
          province_name: "نام استان",
          city_name: "نام شهر",
          purchase_note: "یادداشت سفارش",
          product_name: "نام محصول",
          price: "قیمت",
          code_takhfif: "کد تخفیف",
          emale_takhfif: "اعمال تخفیف",
          saghfe_takhfif:
            "از این کد تخفیف حداکثر تا مبلغ %amount% می توانید استفاده نمایید.",
          nahve_pardakht: "نحوه پرداخت",
          pardakhht_ba_saman: "پرداخت از طریق درگاه بانک سامان",
          nahve_ersal: "نحوه ارسال",
          nahve_ersal_1:
            "با هزینه پیک و پرداخت درب منزل توسط مشتری - تحویل تا 24 ساعت کاری آینده",
          nahve_ersal_2:
            "ارسال به شهرستان با مبلغ 500 هزار تومان به بالا و وزن تا 2 کیلوگرم رایگان می باشد.",
          nahve_ersal_ra_entekhab_konid: "لطفا نحوه ارسال را انتخاب کنید.",
          purchase: "پرداخت",
          mahsuli_baraye_pardakht_nist: "محصولی برای پرداخت وجود ندارد",
          code_taid_ra_vared_konid: "لطفا کد تایید را وارد کنید.",
          code_taid_eshtebah_ast: "کد وارد شده اشتباه است.",
          code_taid_dobare_ersal_shod: "کد تایید دوباره ارسال شد.",
          taid_hesab_karbari: "تایید حساب کاربری",
          confirm_code: "کد تایید",
          confirm: "تایید",
          ersale_dobare_code_taid: "ارسال دوباره کد تایید",
          amuzeshhaye_zivan: "آموزشهای ژیوان",
          amuzeshe_anvae_mataleb: "آموزش انواع مطالب در مورد حیوانات",
          webloge_zivan: "وبلاگ ژیوان",
          mahsulate_sag: "محصولات سگ",
          mahsulate_gorbe: "محصولات گربه",
          tazmin_keyfiyate_kala: "تضمین کیفیت کالا",
          zemanate_bazghasht_vajh: "ضمانت بازگشت وجه",
          pasokhguyi_ali: "پاسخگویی عالی",
          namekarbar_mobile_email_ra_vared_konid:
            "نام کاربری یا شماره موبایل یا ایمیل خود را وارد نمایید.",
          username_or_mobile_or_email: "نام کاربری یا شماره موبایل یا ایمیل",
          register: "ثبت نام",
          reset_password: "بازیابی رمزعبور",
          order: "سفارش",
          ta_mablaghe: "تا مبلغ",
          dawnload_factor: "دانلود فاکتور",
          vaziyate_sefaresh: "وضعیت سفارش",
          sefaresh_baraye_nomayesh_nist: "سفارشی برای نمایش وجود ندارد.",
          az: "از",
          zivan_store: "فروشگاه ژیوان",
          filters: "فیلترها",
          barasase_dastebandi: "بر اساس دسته بندی",
          barasase_brand: "بر اساس برند",
          more_products: "محصولات بیشتر",
          nazare_khod_ra_vared_konid: "لطفا نظر خود را وارد نمایید.",
          nazare_shoma_ba_movafaghiyat_sabt_shod:
            "نظر شما با موفقیت ثبت شد. بعد از تایید مدیر برای کاربران نمایش داده خواهد شد.",
          khata_dar_sabt_nazar: "خطا در ثبت نظر",
          mahsulate_mortabet: "محصولات مرتبط",
          profile_virayesh_shod: "پروفایل با موفقیت ویرایش شد.",
          form_ra_por_konid: "لطفا موارد فرم را کامل وارد کنید.",
          moshakhasate_heyvanat_sabt_shod: `مشخصات حیوان خانگی با موفقیت ثبت شد.`,
          nam_eshtebah_ast: "نام اشتباه می باشد.",
          heyvane_khanegi_vojud_darad:
            "حیوان خانگی با این مشخصات از قبل وجود دارد.",
          khata_dar_sabt: "خطا در ثبت",
          heyvan_hazf_shod: `مشخصات حیوان خانگی با موفقیت حذف شد.`,
          khata_dar_hazf: "خطا در حذف",
          username: "نام کاربری",
          edit: "ویرایش",
          pet_name: "نام",
          age: "سن",
          type: "نوع",
          breed: "نژاد",
          aya_az_hazfe_heyvan_motmaenid: "آیا از حذف حیوان خانگی مطمئن هستید؟",
          delete: "حذف",
          afzudane_heyvan: "افزودن حیوان خانگی",
          username_ra_vared_konid: "لطفا نام کاربری خود را وارد کنید.",
          username_eshtebah_ast: "نام کاربری اشتباه می باشد.",
          hesab_az_gabl_hast: "حسابی با این مشخصات از قبل وجود دارد.",
          mobile_eshtebah_ast: "شماره موبایل اشتباه می باشد.",
          email_eshtebah_ast: "ایمیل اشتباه می باشد.",
          username_shamele_horuf_mibashad:
            "نام کاربری شامل حروف انگلیسی کوچک و بزرگ، اعداد، (_) و (.) می باشد.",
          namekarbar_mobile_email_eshtebah_ast:
            "نام کاربری یا شماره موبایل یا ایمیل اشتباه می باشد.",
          barasase_ghimat: "بر اساس قیمت",
          toman: "تومان",
          dolar: "دلار",
          pardakht_nashode: "پرداخت نشده",
          sefaresh_jadid: "سفارش جدید",
          darhale_amadesazi: "درحال آماده سازی",
          amade_ersal: "آماده ارسال",
          ersal_shode: "ارسال شده",
          tahvil_dadeshode: "تحویل داده شده",
          darhale_barasi_pardakht: "درحال بررسی پرداخت",
          register_review: "ثبت نظر",
          zivan_meta_description: "پت شاپ ژیوان فروشگاه غذا و لوازم حیوانات",
          contact_us_title: "ارتباط با ما",
          posti_baraye_nomayesh_vojud_nadarad: "آموزشی برای نمایش وجود ندارد.",
          etaye_nomayandegi: "اعطای نمایندگی",
          code_melli: 'کد ملی',
          birthdate: 'تاریخ تولد',
          shomare_tamas: 'شماره تماس',
          shabakehayeh_ejtemai: 'شبکه های اجتماعی'
        },
      },
    },
  });

export default i18n;
